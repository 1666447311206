import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { Fragment, useState } from 'react'

// Components
import ArrowLeft from 'inter-frontend-svgs/lib/v2/navigation/arrow-left'
import ArrowRight from 'inter-frontend-svgs/lib/v2/navigation/arrow-right'
import { Modal } from 'src/components/Modal'
import OpenVideo from 'src/components/OpenVideo'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ModalCall from 'src/components/UI/Forms/OpenAccountFundPortabilityForm/index'
import FundPortability from './FundPortability/_index'

import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'

import stepByStep from '../assets/data/stepByStep.json'

import usePageQuery from '../../pageQuery'

import { HowToRequestSection, TextCarousel } from './style'

type RequestDigitalAccountProps= {
  question: string;
  title: string;
  modal: boolean;
  description: string;
  image: string;
}

const WIDTH_MD = 768

const PassoAPasso = () => {
  const data = usePageQuery()
  const [ imageIndex, setImageIndex ] = useState(0)
  const domReady = useDomReady()
  const [ isModalOpen, setIsModalOpen ] = useState(false)
  const width = useWidth(300)

  const ModalCallRight = domReady && (
    <Modal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} locationToRender={document.body}>
      <ModalCall closeModal={() => setIsModalOpen(false)} customOrigin='Portabilidade de Fundos de Investimentos' />
    </Modal>
  )

  const contentCarousel = [
    {
      type: 'image',
      name: 'FundoDisponivel',
      content: '',
    },
    {
      type: 'image',
      name: 'PerfilInvestidor',
      content: '',
    },
    {
      type: 'image',
      name: 'ChatBabiFundos',
      content: '',
    },
    {
      type: 'video',
      name: 'CartaPortabilidadeMobile',
      content:
      (
        <OpenVideo link='https://www.youtube.com/embed/5qQZ6Kh4vFE?&autoplay=1'>
          {width < WIDTH_MD
            ? <Img fluid={data.CartaPortabilidadeMobile.fluid} alt='Assita ao vídeo' className='cursor-pointer' />
            : <Img fluid={data.BgVideoCartaPortabilidadeDesktop.fluid} alt='Assita ao vídeo' className='cursor-pointer' />
          }
        </OpenVideo>
      ),
    },
    {
      type: 'component',
      name: '',
      content: <FundPortability />,
    },
    {
      type: 'image',
      name: 'Andamento',
      content: '',
    },
    {
      type: 'image',
      name: 'Cashback',
      content: '',
    },
    {
      type: 'image',
      name: 'SeusFundosInter',
      content: '',
    },
  ]

  const images = useStaticQuery(graphql`
    query {
      SeusFundosRendemMaisDesktop: imageSharp(fluid: {originalName: { regex: "/seus-fundos-rendem-mais-desktop/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      SeusFundosRendemMaisDesktop: imageSharp(fluid: {originalName: { regex: "/seus-fundos-rendem-mais-desktop/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      SeusFundosRendemMaisDesktop: imageSharp(fluid: {originalName: { regex: "/seus-fundos-rendem-mais-desktop/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      FundoDisponivel: imageSharp(fluid: {originalName: { regex: "/fundo-disponivel/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      PerfilInvestidor: imageSharp(fluid: {originalName: { regex: "/perfil-investidor/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      ChatBabiFundos: imageSharp(fluid: {originalName: { regex: "/chat-babi-fundos/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      CartaPortabilidadeMobile: imageSharp(fluid: {originalName: { regex: "/carta-portabilidade-mobile/" }}) {
        fluid(maxWidth: 312, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      BgVideoCartaPortabilidadeDesktop: imageSharp(fluid: {originalName: { regex: "/bg-video-carta-portabilidade-desktop/" }}) {
        fluid(maxWidth: 552, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      Andamento: imageSharp(fluid: {originalName: { regex: "/andamento/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      Cashback: imageSharp(fluid: {originalName: { regex: "/cashback-no-app/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      SeusFundosInter: imageSharp(fluid: {originalName: { regex: "/seus-fundos-com-o-inter/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  function changeImage (nextSlide: number) {
    setImageIndex(nextSlide)
  }

  const rightArrow = ({ onClick }: never) => (
    <button
      onClick={ onClick } data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--right'
    >
      <ArrowRight width={40} height={40} color='orange--base' />
    </button>
  )

  const leftArrow = ({ onClick }: never) => (
    <button
      onClick={ onClick } data-home='carrossel-arrow' type='button'
      className='border-0 bg-transparent react-multiple-carousel__arrow react-multiple-carousel__arrow--left'
    >
      <ArrowLeft width={40} height={40} color='orange--base' />
    </button>
  )
  return (
    <HowToRequestSection className='bg-white d-flex justify-content-center align-items-center' id='passo-a-passo'>
      {ModalCallRight}
      <div className='container pb-5 pb-md-0'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-0 pr-0'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
              customLeftArrow={leftArrow}
              customRightArrow={rightArrow}
              beforeChange={(nextSlide: number) => changeImage(nextSlide)}
            >
              { stepByStep.map((item: RequestDigitalAccountProps, index: number) => (
                <Fragment key={index}>
                  <TextCarousel>
                    <h2 className='fs-20 lh-25 fs-lg-24 lh-lg-30 text-grayscale--500 question'>{item.question}</h2>
                    <p className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600'>{item.title}</p>
                    {item.modal
                      ? (
                        width < WIDTH_MD
                        ? (
                          <a
                            href='https://ajuda.bancointer.com.br/pt-BR/?is_retargeting=true&shortlink=95dc43b&c=Qr_Code&pid=Chat_Babi&deep_link_value=bancointer://interchat&af_force_deeplink=true'
                            className='text-orange--extra cursor-pointer'
                          >
                            <p className='fs-18 lh-22 text-grayscale--400 description' dangerouslySetInnerHTML={{ __html: item.description }} />
                          </a>
                          )
                        : (
                          <span onClick={() => setIsModalOpen(true)} className='text-orange--extra cursor-pointer'>
                            <p className='fs-18 lh-22 text-grayscale--400 description' dangerouslySetInnerHTML={{ __html: item.description }} />
                          </span>
                          )
                        )
                      : <p className='fs-18 lh-22 text-grayscale--400 description' dangerouslySetInnerHTML={{ __html: item.description }} />
                    }
                  </TextCarousel>
                  {width < WIDTH_MD &&
                    <div className='col-12'>
                      { item.image !== '' && (item.image !== 'CartaPortabilidadeMobile')
                        ? <Img fluid={data[item.image].fluid} alt={item.title} />
                        : item.image !== 'CartaPortabilidadeMobile' ? <FundPortability /> : ''
                      }
                      { item.image === 'CartaPortabilidadeMobile' &&
                        <OpenVideo link='https://www.youtube.com/embed/5qQZ6Kh4vFE?&autoplay=1'>
                          <Img
                            fluid={data.CartaPortabilidadeMobile.fluid} alt='Assita ao vídeo'
                            title='Assita ao vídeo' className='cursor-pointer'
                          />
                        </OpenVideo>
                      }
                    </div>
                  }
                </Fragment>
                ),
              )}
            </DefaultCarousel>
          </div>
          {width >= WIDTH_MD &&
            <div className='col-md-6 col-xl-5 offset-lg-1 offset-xl-2'>
              {
                contentCarousel[imageIndex].type === 'image'
                ? <Img fluid={images[contentCarousel[imageIndex].name].fluid} />
                : contentCarousel[imageIndex].content
              }
            </div>
          }
        </div>
      </div>
    </HowToRequestSection>
  )
}

export default PassoAPasso
